<template>
  <div>
    <nav class="navbar navbar-vertical fixed-start navbar-expand-md" id="sidebar">
      <div class="container-fluid">

        <button v-if="userSession" class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa-solid fa-bars"></i>
        </button>

        <router-link tag="a" :to="$paths.home" class="navbar-brand text-start d-md-none d-flex align-items-center">
          <img class="navbar-brand-img" src="@/assets/img/logo.png" alt="Diag Backoffice" height="50" />
        </router-link>

        <!-- User (xs) -->
        <div class="navbar-user d-md-none" v-if="userSession">
          <!-- Dropdown -->
          <div class="dropdown">
            <!-- Toggle -->
            <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <div class="avatar avatar-sm">
                <i class="fas fa-user-circle display-4 text-dark50"></i>
              </div>
            </a>

            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
              <router-link class="dropdown-item fw-bold" tag="a" :to="$paths.myAccount">
                Minha conta
              </router-link>
              <hr class="dropdown-divider py-0" />
              <router-link class="dropdown-item fw-bold" tag="a" :to="$paths.myPlan">
                Meu plano
              </router-link>
              <hr class="dropdown-divider py-0" />
              <router-link class="dropdown-item fw-bold" tag="a" :to="$paths.aboutApp">
                Sobre o app
              </router-link>
              <hr class="dropdown-divider py-0" />
              <span @click="logout" class="dropdown-item fw-bold text-danger cursor-pointer">Sair</span>
            </div>
          </div>
        </div>

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidebarCollapse">
          <!-- Navigation -->
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="$paths.home" tag="li" class="nav-item">
              <a class="nav-link text-paragraph">
                <i class="sidebar-icon fa-solid fa-home"></i>
                Home
                <!-- <span class="badge bg-light text-dark fw-bold ms-auto">
                {{ $envString }}
              </span> -->
              </a>
            </router-link>
          </ul>

          <hr class="navbar-divider my-3" />
          <!-- <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Cadastros
        </h6> -->
          <ul class="navbar-nav">
            <router-link active-class="router-link-active" :to="$paths.patients.list" tag="li" class="nav-item">
              <a class="nav-link text-paragraph">
                <i class="sidebar-icon fa-solid fa-user-group"></i>
                Pacientes
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.search.list" tag="li" class="nav-item">
              <a class="nav-link text-paragraph">
                <i class="sidebar-icon fa-solid fa-magnifying-glass"></i>
                Buscar
              </a>
            </router-link>
            <router-link active-class="router-link-active" :to="$paths.favorites.list" tag="li" class="nav-item">
              <a class="nav-link text-paragraph">
                <i class="sidebar-icon fa-solid fa-star"></i>
                Favoritos
              </a>
            </router-link>
          </ul>

          <hr class="navbar-divider my-3" />
          <!--
          <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
            Conteúdos
          </h6>
        -->
          <ul class="navbar-nav mb-4">
            <router-link active-class="router-link-active" :to="$paths.aboutApp" tag="li" class="nav-item">
              <a class="nav-link text-paragraph">
                <i class="sidebar-icon fa-regular fa-file-lines"></i>
                Sobre o app
              </a>
            </router-link>
          </ul>
          <router-link :to="$paths.inviteFriends" tag="span" class="cursor-pointer">
            <img src="@/assets/img/invite_banner.svg" alt="" width="100%">
          </router-link>
        </div>
      </div>
    </nav>
    <div class="d-flex justify-content-center bg-primary py-3 d-sm-none" v-if="appRedirect">
      <a @click.prevent="goToApp" class="text-white text-subtitle">
        <i class="fas fa-arrow-left"></i>
        Voltar para o app
      </a>
    </div>
  </div>

  <!-- MOBILE -->
  <!-- <nav class="navbar navbar-vertical fixed-start navbar-expand-md " id="sidebar">
    <div class="container-fluid">
      <router-link tag="a" :to="userSession
        ? `${$paths.plans}?user_id=${userSession.id}`
        : $paths.plans
        " class="navbar-brand">
        <img class="navbar-brand-img" src="@/assets/img/logo.png" alt="Diag Backoffice" width="122" height="40" />
      </router-link>

      <button v-if="userSession" class="navbar-toggler text-dark" type="button" data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="fas fa-bars text-dark display-4"></i>
      </button>

      <div v-else>
        <a @click.prevent="goToApp" class="text-primary text-subtitle">
          <i class="fas fa-arrow-left"></i>
          Voltar para o app
        </a>
      </div>

      <div class="collapse navbar-collapse bg-white" id="sidebarCollapse">

        <ul class="navbar-nav my-3">
          <router-link active-class="router-link-active" :to="$paths.myPlan" tag="li" class="nav-item">
            <a class="nav-link text-paragraph text-dark"> Meu plano </a>
          </router-link>
          <li @click="logout" active-class="router-link-active" class="nav-item">
            <a class="nav-link text-paragraph text-dark"> Sair </a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->
</template>

<script>
export default {
  data() {
    return {
      appRedirect: this.$route.query.appRedirect
    }
  },
  methods: {
    async logout() {
      try {
        // await this.$userService.logout();
        this.$store.commit("clearSession");
      } catch (error) {
        console.log("ERRO NO LOGOUT", error);
      } finally {
        // this.$router.push(this.$paths.plans);
        this.$router.push(this.$paths.login);
      }
    },
    goToApp() {
      const url = encodeURI("diag:///subscription/done");
      // const url = encodeURI("diag://");
      console.log(url);
      window.location = url;
    },
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
};
</script>

