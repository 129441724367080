import NetworkService from "@/services/NetworkService";
import store from "@/store";

export default class ContentsService {
  constructor() {
    this.network = new NetworkService();
  }
  list = async (filters) => {
    let session = store.state.userSession;

    let res = await this.network.get(this.network.endpoints.contents, filters);

    res.data = res.data.map((elem) => ({
      ...elem,
      disabled: elem.only_subscribers && !session.isPremiumAccount,
    }));

    return res;
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.contents}/${id}`);
  };
  listContentTabs = async () => {
    let res = await this.network.get(this.network.endpoints.contentItemsTabs);
    res.data.unshift({
      id: "tab_general",
      name: "Geral",
    });
    return res;
  };
  listAreas = async (params) => {
    let q;
    if (params?.q) {
      q = params.q;
      delete params.q;
    }

    let res = await this.network.get(this.network.endpoints.areas, params);
    let count = 0;

    res.data = res.data
      .map((r) => {
        if (q) {
          let reg = /[\u0300-\u036f]/g;
          r.category = r.category.filter((c) =>
            c.name
              .toLowerCase()
              .normalize("NFD")
              .replace(reg, "")
              .includes(q.toLowerCase().normalize("NFD").replace(reg, ""))
          );
        }
        count += r.category.length;
        r.category.map((c) => {
          c.subItemsCount = c.subcategory_count + c.content_count;
          c.subcategory_in_next_step = c.subcategory_count > 0;
          c.imageUrl = process.env.VUE_APP_IMG_BASE_URL + c.image_url;
          c.formattedCount =
            c.subItemsCount == 1
              ? `${c.subItemsCount} item`
              : `${c.subItemsCount} itens`;
        });
        return r;
      })
      .filter((r) => r.category.length);
    res.total = count;
    return res;
  };
  listSubCategories = async (params) => {
    let res = await this.network.get(this.network.endpoints.categories, params);
    res.data.map((r) => {
      r.subItemsCount = r.count_subcategory + r.content_count;
      r.subcategory_in_next_step = r.count_subcategory > 0;
      r.imageUrl = process.env.VUE_APP_IMG_BASE_URL + r.image_url;
      r.formattedCount =
        r.subItemsCount == 1
          ? `${r.subItemsCount} item`
          : `${r.subItemsCount} itens`;
    });
    return res;
  };
  listCategoryContents = async (params) => {
    let session = store.state.userSession;

    let res = await this.network.get(this.network.endpoints.contents, params);

    res.data = res.data.map((elem) => ({
      ...elem,
      disabled: elem.only_subscribers && !session.isPremiumAccount,
    }));

    return res;
  };
  setFavorite = (id, favorite) => {
    if (favorite) {
      return this.network.post(`${this.network.endpoints.like}`, {
        content_id: id,
      });
    } else {
      return this.network.post(`${this.network.endpoints.dislike}`, {
        content_id: id,
      });
    }
  };
}
