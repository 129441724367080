import NetworkService from "@/services/NetworkService";

export default class MedicinesService {
  constructor() {
    this.network = new NetworkService();
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.medicines, filters);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.medicines}/${id}`);
  };
  listMedications = (params) => {
    return this.network.get(
      `${this.network.endpoints.medicationNames}`,
      params
    );
  };
  listCids = (params) => {
    return this.network.get(`${this.network.endpoints.cids}`, params);
  };
}
