import NetworkService from "@/services/NetworkService";

export default class PatientsService {
  constructor() {
    this.network = new NetworkService();
  }
  create = (params) => {
    if (params.cpf) {
      // deixa só numeros na string
      params.cpf = params.cpf.replace(/\D/g, "");
    }
    return this.network.post(this.network.endpoints.patients, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.patients, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.patients}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.patients}/${id}`);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.patients}/${id}`, params);
  };
}
