import { Modal } from "bootstrap";

export default {
  name: "ConfirmationAlert",
  mounted() {
    this.$eventBus.$on(
      "showConfirmationAlert",
      ({ title, message, confirmCallback, cancelCallback }) => {
        this.$set(this, "confirmCallback", confirmCallback);
        this.$set(this, "cancelCallback", cancelCallback);
        this.$set(this, "message", message);
        this.$set(this, "title", title);
        this.confirmAlertModal = new Modal(
          document.getElementById("confirmAlertModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
        );
        this.confirmAlertModal.toggle();
      }
    );
  },
  data() {
    return {
      confirmAlertModal: null,
      confirmCallback: () => {},
      cancelCallback: () => {},
      title: null,
      message: null,
    };
  },
  methods: {
    alertClosed() {
      this.cancelAction();
    },
    confirmAction() {
      this.confirmCallback();
      this.confirmAlertModal.toggle();
    },
    cancelAction() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      this.confirmAlertModal.toggle();
    },
  },
  destroyed() {},
};
