import NetworkService from "./NetworkService";

export default class AddressService {
  constructor() {
    this.network = new NetworkService();
  }
  list = (params) => {
    return this.network.get(this.network.endpoints.address, params);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.address, params);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.address}/${id}`, params);
  };
  getAddressFromCep = (cep) => {
    return fetch(`https://viacep.com.br/ws/${cep.clear()}/json`).then((r) =>
      r.json()
    );
  };
}
