<template>
  <div class="main-content">
    <div class="container-fluid" :class="{ 'pb-5': bottomSpace }">
      <div class="row justify-content-center">
        <div :class="col">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    col: {
      type: String,
      default: 'col-10'
    },
    bottomSpace: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style></style>