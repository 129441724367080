<template>
  <!-- <div style="z-index: 99999999; top: 100px; left: 20px" role="alert" aria-live="assertive" aria-atomic="true" -->
  <div role="alert" aria-live="assertive" aria-atomic="true" class="toast position-fixed" id="main-toast"
    data-bs-autohide="false">
    <div class="toast-header border-0 pb-1">
      <span v-if="type == 'success'" class="fas fa-check-circle text-primary"></span>
      <span v-if="type == 'danger'" class="fas fa-times-circle text-danger"></span>
      <strong class="me-auto ms-2" style="word-break: break-word">{{
        title
      }}</strong>

      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body pt-0 pb-4 text-muted">
      <small style="word-break: break-word">{{ msg }}</small>
    </div>
    <div class="progress progress-sm">
      <div ref="filledProgressToast" id="filled-progress-toast" class="progress-bar" role="progressbar"
        style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</template>

<style scoped>
.toast {
  z-index: 99999999;
  top: 100px;
  right: 30px;
  width: 400px
}

@media screen and (max-width: 575px) {
  .toast {
    width: calc(100vw - 40px);
    left: 20px;
    right: unset;
  }
}
</style>

<script>
import { Toast } from "bootstrap";
import $ from "jquery";
export default {
  mounted() {
    this.$eventBus.$on("showToast", this.handleToast);
  },
  name: "NotificationToast",
  data() {
    return {
      toast: null,
      type: "success",
      title: "Oi eu sou o goku",
      msg: "Tudo bom?",
    };
  },
  methods: {
    handleToast(data) {
      const $this = this;
      this.toast = new Toast(document.getElementById("main-toast"));
      this.type = data.type || "success";
      this.title = data.title;
      this.msg = data.msg;
      this.toast.show();
      $(this.$refs.filledProgressToast).animate(
        { width: "0%" },
        5000,
        function () {
          setTimeout(() => {
            $(this).css("width", "100%");
          }, 500);
          $this.toast.hide();
        }
      );
    },
  },
};
</script>


