<template>
    <div class="mb-3">
        <p class="text-label" v-if="label">{{ label }}</p>
        <div class="form-check">
            <input class="form-check-input" type="radio" :value="true" v-model="model.$model" :id="`${id}Yes`">
            <label class="form-check-label" :for="`${id}Yes`">
                Sim
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" :value="false" v-model="model.$model" :id="`${id}No`" checked>
            <label class="form-check-label" :for="`${id}No`">
                Não
            </label>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        model: {
            required: false,
            default: () => { },
        },
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
    },
};
</script>
