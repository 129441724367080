import { render, staticRenderFns } from "./ConfirmationAlert.vue?vue&type=template&id=357b795d&scoped=true&"
import script from "./ConfirmationAlert.vue?vue&type=script&lang=js&"
export * from "./ConfirmationAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "357b795d",
  null
  
)

export default component.exports