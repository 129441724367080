import moment from 'moment'

export default class CalculatorService {
  ALPHA = (female, black) => {
    if (female) {
      if (black) return 166
      return 144
    }
    if (black) return 163
    return 141
  }

  BETA = (sericCreatinina, female) => {
    if (female) {
      return sericCreatinina <= 0.7 ? -0.329 : 1.209
    } else {
      sericCreatinina <= 0.9 ? -0.411 : 1.209
    }
  }

  /**
   *------------  BOMBA DE INFUSAO ------------- 
   */


  /**
   * @param {*} dropsPerMinute gota/min
   * @description Gotas/mim para mL/h >>> 1 gota/min = 3mL/h
   */
  dropsMinToMlh = (dropsPerMinute) => {
    let result = 0
    if (dropsPerMinute != null) {
      result = Number(dropsPerMinute) * 3
    }
    return {
      result,
      formatted: `${result} ml/h`
    }
  }


  /**
   * @param {*} solution solucao em mg/ml
   * @param {*} mlh vazao em ml/h
   * @param {*} weight peso em kg
   * @description ml/h para mcg/kg/min
   */
  mlhToMcgKgMin = (solution, flowRate, weight, totalVolume) => {
    let result = 0
    if (solution && flowRate && weight && totalVolume) {
      if (totalVolume > 0 && weight > 0) {
        const k = (solution * 1000) / totalVolume / 60
        result = (flowRate * k / weight)
      }
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mcg/kg/min`
    }
  }


  /**
   * @param {*} solution solucao em mg/ml
   * @param {*} flowRate vazao em  mcg/kg/min
   * @param {*} weight peso em kg
   * @description  mcg/kg/min para ml/h
   */
  mcgKgMinToMlh = (solution, flowRate, weight, totalVolume) => {
    let result = 0
    if (solution && flowRate && weight && totalVolume) {
      solution = parseFloat(solution)
      flowRate = parseFloat(flowRate)
      weight = parseFloat(weight)
      totalVolume = parseFloat(totalVolume)
      const k = (flowRate * weight * 60) / 1000
      result = (k * totalVolume / solution)
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} ml/h`
    }


  }


  /**
   *------------  CALCULADORAS DIVERSAS ------------- 
   */

  /**
  * @param {*} weight peso em kg
  * @param {*} height altura em cm
  * @description (peso em kg) / (altura em cm)ˆ2
  */
  IMC = (weight, height) => {
    let result = 0
    if (weight && height && height > 0) {
      height = parseFloat(height)
      weight = parseFloat(weight)
      height = height / 100
      result = weight / (height * height)
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')}`
    }
  }



  /**
    * @param {*} weight peso em kg
    * @param {*} height altura em cm
    * @description Gradiente albumina soro-ascite (GASA) = albumina sérica (g/dL) - albumina no liquido ascítico (g/dL)
    */

  gasaGradient(albuminaSerica, albuminaLiquido) {
    let result = 0
    console.log(albuminaSerica, albuminaLiquido)
    if (albuminaSerica && albuminaLiquido) {
      albuminaSerica = parseFloat(albuminaSerica)
      albuminaLiquido = parseFloat(albuminaLiquido)
      result = albuminaSerica - albuminaLiquido
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} g/dL`
    }
  }


  /**
    * @param {*} weight peso em kg
    * @param {*} height altura em cm
    * @description Gradiente albumina soro-ascite (GASA) = albumina sérica (g/dL) - albumina no liquido ascítico (g/dL)
    */

  gestationalAgeByDum(date) {
    let result = 0
    let text = '0 dia(s)'
    if (date) {
      const realDate = moment(date, 'DD/MM/YYYY', true)
      if (realDate.isValid()) {
        let diffDays = Math.abs(moment().diff(realDate, 'days'))
        result = diffDays
        const weeks = Math.floor(Number(diffDays / 7)).toFixed(0)
        const days = Number(diffDays % 7).toFixed(0)
        if (weeks > 0) {
          text = `${weeks} semana(s)`
          if (days > 0) {
            text = `${text} e ${days} dia(s)`
          }
        } else {
          text = `${days} dia(s)`
        }
      }
    }
    return {
      result,
      formatted: `${text}`
    }
  }
  gestationalAgeByDpp(date) {
    let result = 0
    let text = '0 dia(s)'
    if (date) {
      const realDate = moment(date, 'DD/MM/YYYY', true)
      if (realDate.isValid()) {
        const dumDate = moment(realDate).add(-280, 'days')
        let diffDays = Math.abs(moment().diff(dumDate, 'days'))
        result = diffDays
        const weeks = Math.floor(Number(diffDays / 7)).toFixed(0)
        const days = Number(diffDays % 7).toFixed(0)
        if (weeks > 0) {
          text = `${weeks} semana(s)`
          if (days > 0) {
            text = `${text} e ${days} dia(s)`
          }
        } else {
          text = `${days} dia(s)`
        }
      }
    }
    return {
      result,
      formatted: `${text}`
    }
  }
  gestationalAgeByUsg(usgDate, weeks, days) {
    let result = 0
    let text = '0 dia(s)'
    if (usgDate && weeks != null && days != null) {
      weeks = parseFloat(weeks)
      days = parseFloat(days)
      const realDate = moment(usgDate, 'DD/MM/YYYY', true)
      if (realDate.isValid()) {
        let diffDays = Math.abs(realDate.diff(moment(), 'days'))
        console.log(diffDays)
        result = diffDays + (weeks * 7) + days
        const weeksResult = Math.floor(Number(result / 7)).toFixed(0)
        const daysResult = Number(result % 7).toFixed(0)
        if (weeksResult > 0) {
          text = `${weeksResult} semana(s)`
          if (daysResult > 0) {
            text = `${text} e ${daysResult} dia(s)`
          }
        } else {
          text = `${daysResult} dia(s)`
        }
      }
    }
    return {
      result,
      formatted: `${text}`
    }
  }
  /**
     * @param {*} sistolicPressure Pressão arterial sistólica
     * @param {*} diastolicPressure Pressão arterial diastólica
     * @description (Pressão arterial sistólica + 2 x pressão arterial diastólica) / 3
     */
  averageBloodPressure = (sistolicPressure, diastolicPressure) => {
    let result = 0
    if (sistolicPressure && diastolicPressure) {
      sistolicPressure = parseFloat(sistolicPressure)
      diastolicPressure = parseFloat(diastolicPressure)
      result = (sistolicPressure + 2 * diastolicPressure) / 3
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mmHg`
    }
  }


  /**
   * @param {*} qtInterval Intervalo QT em ms
   * @param {*} heartRate Frequência cardíaca
   * @description 1 - QT corrigido (ms) = Intervalo QT em ms + 1,75 * (Frequência cardíaca - 60)
   * 2 - RRIntervalo = 60 / FrequênciaCardíaca
      QTICorrigido = QTIntervalo / sqr(RRIntervalo)
  
      https://www.msdmanuals.com/pt-br/profissional/multimedia/clinical-calculator/corre%C3%A7%C3%A3o-do-intervalo-qt-ecg
      https://pt.my-ekg.com/calculadoras-ecg/calculadora-qt-corrigido.php
   */

  correctedQT = (qtInterval, heartRate) => {

    let result = 0
    if (qtInterval && heartRate) {
      qtInterval = parseFloat(qtInterval.replace(',', '.'))
      heartRate = parseFloat(heartRate.replace(',', '.'))
      let rrInterval = 60 / heartRate
      // result = qtInterval + 1.75 * (heartRate - 60)
      result = qtInterval / Math.sqrt(rrInterval)
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} ms`
    }
  }



  /**
   *------------  ELETRÓLITOS SERICOS ------------- 
   */


  /**
  * @param {*} albumina albumina sérica g/dL
  * @param {*} calcium Cálcio sérico(mEq/L)
  * @description Cálcio corrigido pela albumina = [( 4 - albumina sérica g/dL)*0,8]+ Cálcio sérico(mEq/L)
  * @returns Resultado em mEq/L
  * referencias
  * https://endocrinologico.com.br/correcao-de-calcio/
  * 
  */
  calciumCorrectedByAlbumina = (albumina, calcium) => {
    let result = 0
    if (albumina > 0 || calcium > 0) {
      albumina = parseFloat(albumina)
      calcium = parseFloat(calcium)
      result = ((4 - albumina) * 0.8) + calcium
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mEq/L`
    }
  }

  /**
  * @param {*} sodium Sódio sérico (mEq/L)
  * @param {*} glicose glicose em mg/dL
  * @description Sódio corrigido pela glicemia = Sódio sérico + [1,6 x ({glicose - 100}/100)]
  * @returns Resultado em mEq/L
  * https://www.msdmanuals.com/medical-calculators/SodiumHyperglycemia-pt.htm
  */
  sodiumCorrectedByGlycemia = (sodium, glicose) => {

    let result = 0
    if (sodium > 0 || glicose > 0) {
      sodium = parseFloat(sodium)
      glicose = parseFloat(glicose)
      result = sodium + (1.6 * ((glicose - 100) / 100))
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mEq/L`
    }
  }

  /**
  * @param {*} sodium Sódio sérico mg/dL
  * @param {*} glicose mg/dL
  * @param {*} ureia ureia em mg/dL
  * @description Osmolaridade plasmática em mOsm/L = 2 x Sódio sérico em mEq/L + glicose em mg/dL/18 + ureia em mg/dL
  * @returns Resultado em mOsm/L
  * referencia https://www.youtube.com/watch?v=UeMeFYXBwLQ
  */

  plasmaticOsmolarity = (sodium, glicose, ureia) => {
    let result = 0
    if (sodium && glicose && ureia) {
      sodium = parseFloat(sodium)
      glicose = parseFloat(glicose)
      ureia = parseFloat(ureia)
      result = (2 * sodium) + (glicose / 18) + (ureia / 6)
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')}  mOsm/L`
    }
  }

  /**
  * @param {*} targetHb hemoglobina (Hb) alvo
  * @param {*} currentHb hemoglobina (Hb) atual
  * @param {*} weight peso do paciente em Kg
  * @description Déficit de ferro corporal = (Hb alvo – Hb atual) x peso do paciente em Kg x 2,4 + 500
  * @returns Resultado em mg
  * referencia https://www.mdcalc.com/calc/10139/ganzoni-equation-iron-deficiency-anemia#evidence
  */

  ironDeficiency = (targetHb, currentHb, weight) => {
    let result = 0
    if (targetHb && currentHb && weight) {
      targetHb = parseFloat(targetHb)
      currentHb = parseFloat(currentHb)
      weight = parseFloat(weight)
      result = (targetHb - currentHb) * weight * 2.4 + 500
    }
    return {
      result,
      formatted: `${result.toFixed(0).replace('.', ',')} mg`
    }
  }










  /**
   *------------  FUNÇÃO RENAL ------------- 
   */



  /**
   * @param {*} age idade
   * @param {*} weight massa corporal [quilogramas]
   * @param {*} sericCreatinina creatinina serica
   * @description Clearance de Creatinina (Cockroft-Gault) = (140 - idade [anos]) X massa corporal [quilogramas]) / creatinina serica X 72
   * - Se mulher, multiplicar resultado por 0,85
   * @returns Resultado em mL/min para homem e mulher
   * referencia https://www.sbn.org.br/profissional/utilidades/calculadoras-nefrologicas/
   */

  creatininaCockroftGault = (age, weight, sericCreatinina, gender) => {
    let result = 0
    if (age && weight && sericCreatinina && sericCreatinina > 0) {
      age = parseFloat(age)
      weight = parseFloat(weight)
      sericCreatinina = parseFloat(sericCreatinina)
      result = (140 - age) * weight / (sericCreatinina * 72)
    }
    result = gender == 'female' ? result * 0.85 : result
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mL/min`
    }

  }


  /**2
  * @param {*} age idade
  * @param {*} sericCreatinina Creatinina sérica
  * @param {*} gender genero
  * @param {*} black se negro
  * @description  Clearance de Creatinina (MDRD) simplificada = 186 X (Creatinina sérica)ˆ-115 x (Idade em anos) ˆ-203 
  * - multiplicar por 0,742 (se gênero feminino)
  * - multiplicar por 1,212 (se afrodescedente)
  * @returns Resultado em mL/min/1,73m2
  */

  creatininaMDRD = (age, sericCreatinina, gender, black = false) => {
    let result = 0
    if (age && sericCreatinina && gender) {
      age = parseFloat(age)
      sericCreatinina = parseFloat(sericCreatinina)
      result = 186 * Math.pow(sericCreatinina, -1.154) * Math.pow(age, -0.203)
    }
    if (gender === 'female') {
      result = result * 0.742
    }
    if (black) {
      result = result * 1.212
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mL/min/1,73m²`
    }
    return result
  }


  /**
  * @param {*} age idade
  * @param {*} sericCreatinina Creatinina sérica
  * @param {*} female se mulher
  * @param {*} black se negro
  * @param {*} kappa 
  * @description  Clearance de Creatinina (CKD-EPI) = alfa X (Creatininasérica/Kappa)beta X (0,993 )idade em anos
  * @returns Resultado em mL/min/1,73m2
  */

  // creatininaCKDEPI = (age, sericCreatinina, kappa, gender = 'male', black = false) => {
  creatininaCKDEPI = (age, sericCreatinina, gender = 'male') => {
    // let result = this.ALPHA(female, black) * Math.pow((sericCreatinina / kappa), this.BETA(sericCreatinina, female)) * (Math.pow(0.993, age))
    let result = 0
    if (age && sericCreatinina) {
      age = parseFloat(age)
      sericCreatinina = parseFloat(sericCreatinina)
      let k
      let calcAge = Math.pow(0.9938, age)
      let minCreatin
      let maxCreatin
      if (gender === 'male') {
        k = 0.9
        minCreatin = Math.min(sericCreatinina / k, 1)
        minCreatin = Math.pow(minCreatin, -0.302)

        maxCreatin = Math.max(sericCreatinina / k, 1)
        maxCreatin = Math.pow(maxCreatin, -1.200)

        result = 142 * minCreatin * maxCreatin * calcAge
      } else {
        k = 0.7
        minCreatin = Math.min(sericCreatinina / k, 1)
        minCreatin = Math.pow(minCreatin, -0.241)

        maxCreatin = Math.max(sericCreatinina / k, 1)
        maxCreatin = Math.pow(maxCreatin, -1.200)

        result = 142 * minCreatin * maxCreatin * calcAge * 1.012
      }

    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mL/min/1,73m²`
    }
  }


  /**
   * @param {*} sodium Sódio sérico em mEq/L
   * @param {*} cloro Cloro sérico em mEq/L
   * @param {*} bicarbonate em mEq/L
   * @description  Ânion Gap = (Sódio sérico + Potássio sérico) – (Cloro sérico + bicarbonate) Todas as variáveis em mEq/L
   * - Significado: valores acima de 32 têm pior prognóstico e podem se beneficiar com corticoterapia
   * referencia https://www.mdcalc.com/calc/1669/anion-gap#evidence
   */

  anionGap = (sodium, cloro, bicarbonate) => {
    let result = 0
    if (sodium && cloro && bicarbonate) {
      sodium = parseFloat(sodium)
      cloro = parseFloat(cloro)
      bicarbonate = parseFloat(bicarbonate)
      result = sodium - (cloro + bicarbonate)
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} mEq/L`
    }

  }








  /**
   *------------  ESCORES ------------- 
   */


  /**
   * @param {*} protrombina Tempo de Protrombina do paciente em segundos
   * @param {*} controlProtrombina Tempo de protrombina controle
   * @param {*} bilirrubina bilirrubina total 
   * @description  Escore de Maddrey = 4,6 x (Tempo de Protrombina do paciente – Tempo de protrombina controle) + bilirrubina total
   * - Significado: valores acima de 32 têm pior prognóstico e podem se beneficiar com corticoterapia
   */
  maddreyEscore = (protrombina, controlProtrombina, bilirrubina) => {
    try {
      let result = 0
      if (protrombina && controlProtrombina && bilirrubina) {
        protrombina = parseFloat(protrombina.replace(',', '.'))
        controlProtrombina = parseFloat(controlProtrombina.replace(',', '.'))
        bilirrubina = parseFloat(bilirrubina.replace(',', '.'))
        result = 4.6 * (protrombina - controlProtrombina) + bilirrubina
      }
      return {
        result,
        formatted: `${result.toFixed(2).replace('.', ',')}`
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  waterDefict = (sodium, bodyWater) => {
    let result = 0
    if (sodium && bodyWater) {
      sodium = parseFloat(sodium)
      bodyWater = parseFloat(bodyWater)
      result = ((sodium - 140) / 140) * bodyWater
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} L`
    }
  }
  totalBodyWater = (weight, gender, elderly) => {
    let result = 0
    let multiplier = gender == 'male' ? 0.6 : 0.5
    let divider = 1
    if (weight && gender) {
      if (elderly) {
        divider = gender == 'male' ? 0.5 : 0.45
      }
      result = weight * multiplier / divider
    }
    return {
      result,
      formatted: `${result.toFixed(2).replace('.', ',')} L`
    }
  }
}
