<template>
    <div class="mb-3">
        <p class="text-label" v-if="label">{{ label }}</p>
        <div class="form-check" v-for="(item, index) in options" :key="'option_' + index">
            <input class="form-check-input" type="radio" :value="item.id" v-model="model.$model" :id="'option_' + index"
                @change="onChange($event)">
            <label class="form-check-label" :for="'option_' + index">
                {{ item.name }}
            </label>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        model: {
            required: false,
            default: () => { },
        },
        label: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onChange(event) {
            this.$emit("onChange", event.target.value);
        },
    },
};
</script>
