<template>
    <ModalComponent id="about_app_modal" :large="true" :h100="true">
        <template v-slot:body>
            <LoadingContainer v-if="loading" class="h-100" />
            <div v-else v-html="text"></div>
        </template>
    </ModalComponent>
</template>

<script>
import { Modal } from "bootstrap";
export default {
    name: "AboutAppModal",
    mounted() {
        this.aboutAppModal = new Modal(document.getElementById("about_app_modal"));
        this.$eventBus.$on("openAboutAppModal", ({ key }) => { this.getData(key) });
    },
    data() {
        return {
            loading: false,
            text: ""
        };
    },
    methods: {
        async getData(key) {
            try {
                if (!key) return;
                this.loading = true;
                this.aboutAppModal.toggle();
                const res = await this.$userService.settings(key);
                this.$set(this, "text", res.data.value);
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>