import NetworkService from "@/services/NetworkService";
import store from "@/store";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
  }

  planPrices = {
    price_1NQGirCzpo9pVOak0lLV5vWn: {
      type: "monthly",
      typeLabel: "Mensal",
      value: 14.9,
      valueLabel: "R$14,90",
    },
    price_1NQGjhCzpo9pVOakK081v1zR: {
      type: "yearly",
      typeLabel: "Anual",
      value: 119.9,
      valueLabel: "R$119,90",
    },
  };

  resetPassword(params) {
    return this.network.post(this.network.endpoints.passwordReset, params);
  }
  subscribe(params) {
    return this.network.post(this.network.endpoints.subscription, params);
  }
  cancelSubscription() {
    return this.network.post(this.network.endpoints.cancelSubscription);
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.users, params);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };
  show = (id, params) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`, params);
  };
  getUserData = async (id) => {
    const res = await this.show(id);
    let data = res.data;

    const hasStripeSubs =
      data.subs &&
      (data.subs.stripe_status === "active" ||
        data.subs.stripe_status === "trialing");

    const hasIAPSubs =
      data.inapp_subs &&
      (data.inapp_subs.plan_status === "paid" ||
        data.inapp_subs.stripe_status === "trial");
    data.isPremiumAccount = hasIAPSubs || hasStripeSubs;

    return data;
  };
  syncSession = async () => {
    let data = await this.getUserData(store.state.userSession?.id);
    store.dispatch("syncSessionWithApi", data);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  login = async (params) => {
    const res = await this.network.post(this.network.endpoints.login, params);
    if (res.data.type !== "admin") {
      console.log(res);
      await store.dispatch("saveToken", res.access_token);
      const data = await this.getUserData(res.data.id);
      return { ...res, data };
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  forgotPassword = (params) => {
    return this.network.post(this.network.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.network.post(`${this.network.endpoints.users}/logout`);
  };
  settings = (key) => {
    return this.network.get(`${this.network.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`${this.network.endpoints.settings}/${key}`, {
      value,
    });
  };
  homeInfo = () => {
    return this.network.get(`${this.network.endpoints.homeInfo}`);
  };
  listCrashes = () => {
    return this.network.get(`${this.network.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  validateCoupon = (coupon) => {
    return this.network.get(
      this.network.endpoints.coupons,
      { type: "web", coupon },
      false
    );
  };
  planDetails = () => {
    return this.network.get(`${this.network.endpoints.planDetails}`);
  };
  getStatus = (key) => {
    switch (key) {
      case "active":
      case "trialing":
      case "canceled_trialing":
      case "canceled_active":
        return "Assinante";
      case "incomplete":
        return "Incompleto";
      case "incomplete_expired":
        return "Expirado";
      case "past_due":
        return "Vencido";
      case "unpaid":
        return "Não pago";
      case "paused":
        return "Pausado";
      default:
        break;
    }
  };
  async listFavorites() {
    let session = store.state.userSession;

    let res = await this.network.get(`${this.network.endpoints.myFavorites}`);

    res.data.map((elem) => {
      elem.firstCategoryIcon = `${process.env.VUE_APP_IMG_BASE_URL}${elem.content.first_category.image_url}`;
      elem.disabled =
        elem.content.only_subscribers && !session.isPremiumAccount;
    });

    return res;
  }
  listNotifications = (params) => {
    return this.network.get(this.network.endpoints.notifications, params);
  };
  listUnseenNotifications = () => {
    return this.network.get(
      this.network.endpoints.unseenNotifications,
      {},
      false
    );
  };
}
